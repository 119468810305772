<template>
  <nav class="pagination" role="navigation" aria-label="pagination">
    <a class="pagination-previous" :disabled="page === 1" @click="set(page - 1)"
      >Previous</a
    >
    <a class="pagination-next" :disabled="page >= pages" @click="set(page + 1)"
      >Next page</a
    >
    <ul v-if="pages > 0" class="pagination-list">
      <li>
        <a
          class="pagination-link"
          :class="{ 'is-current': page === 1 }"
          aria-label="Goto page 1"
          @click="set(1)"
          >1</a
        >
      </li>
      <li><span v-if="showFirstEllipsis" class="pagination-ellipsis">&hellip;</span></li>
      <li>
        <a
          v-if="page - 1 > 1"
          class="pagination-link"
          :aria-label="`Page ${page - 1}`"
          @click="set(page - 1)"
          >{{ page - 1 }}</a
        >
      </li>
      <li>
        <a
          v-if="page > 1"
          class="pagination-link"
          :class="{ 'is-current': page > 1 }"
          :aria-label="`Page ${page}`"
          aria-current="page"
          >{{ page }}</a
        >
      </li>
      <li>
        <a
          v-if="page !== pages && page + 1 < pages"
          class="pagination-link"
          :aria-label="`Goto page ${page + 1}`"
          @click="set(page + 1)"
          >{{ page + 1 }}</a
        >
      </li>
      <li>
        <span v-if="showSecondEllipsis" class="pagination-ellipsis">&hellip;</span>
      </li>
      <li>
        <a
          v-if="page !== pages"
          class="pagination-link"
          :aria-label="`Goto page ${pages}`"
          @click="set(pages)"
          >{{ pages }}</a
        >
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    page: {
      type: Number,
      required: true
    },
    pages: {
      type: Number,
      required: true
    },
    fn: {
      type: String,
      required: false,
      default: () => 'fetch'
    }
  },
  methods: {
    set(page) {
      if (page > 0 && page <= this.pages) {
        this.$emit('change', page)
      }
    }
  },
  computed: {
    showFirstEllipsis() {
      const { page, pages } = this
      return page > 2 && page - 1 > 2 && pages > 3
    },
    showSecondEllipsis() {
      const { page, pages } = this
      return page !== pages  && pages - page > 2 && pages > 3
    }
  }
}
</script>
